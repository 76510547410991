import Vue from "vue";
import Router from "vue-router";
Vue.use(Router);



let routes = [
  { path: '/', component: resolve => require(["../page/home/index.vue"], resolve), meta:{title: 'Home'} },
  // -------------------------------*******************-------------------------------
  { path: '*', component: resolve => require(["../page/404.vue"], resolve), meta:{title: 'Home'} }
];

const VueRouterPush = Router.prototype.push;
Router.prototype.push = function push(to) {
  try{
    return VueRouterPush.call(this, to).catch(err => err);
  }catch(err){
    console.log(err)
  }
  
};

let router = new Router({
  mode: "history",
  base: "/",
  routes
});
router.beforeEach((to, from, next) => {
  next()
  
});

export default router;
