import Vue from 'vue'
import App from './App.vue'
import router from "./router";
import store from "./store/store.js";

Vue.config.productionTip = false

// mixin引入
import mixin from './mixin';
Vue.mixin(mixin);

// 自动加载自定义组件
let map = require.context("./components", true, /\.vue$/);
map.keys().map(item => {
  let name = item
    .split("/")
    .pop()
    .split(".")[0];
  Vue.component(name, map(item).default);
});

new Vue({
  render: h => h(App),
  router,
  store
}).$mount('#app')
