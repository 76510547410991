import Vue from 'vue';
import Vuex from 'vuex';
Vue.use(Vuex);
import global from './data/global';

const store = new Vuex.Store({
  modules: {
    global
  }
})


export default store;