<template>
    <div class="session">
        <div class="web_detail" v-if="peerMeta">
            <div class="web_logo">
                <img :src="peerMeta.icons[0]" alt="" class="icon_logo">
            </div>
            <div class="web_tip">{{peerMeta.name}} {{langJson("Request to connect your wallet")}}</div>
            <div class="web_url">{{peerMeta.url}}</div>
            <div class="serve">{{langJson("This service is supported by the waletconnect protocol")}}</div>
        </div>
        <div class="wait" v-else>
            <GI class="loading" :rotate="true" type="png" name="backup_loading" ws="30" hs="30"></GI>
            <div class="wait_text">{{langJson("Wait loading")}}</div>
        </div>
        
    </div>
</template>
<script>
export default {
    props:{
        peerMeta:{
            type:Object,
            default:() => {
                return null
            }
        }
    }
}
</script>
<style lang="scss" scoped>
.session{
    .web_detail{
        .serve{
            font-family: 'Titillium Web';
            font-style: normal;
            font-weight: 700;
            font-size: 12px;
            line-height: 140%;
            text-align: center;
            margin-top:80px;
        }
        .web_logo{
            margin-top:40px;
            .icon_logo{
                border-radius: 50%;
                width:60px;
                height:60px;
            }
        }
        .web_tip{
            font-family: 'Titillium Web';
            font-style: normal;
            font-weight: 700;
            font-size: 18px;
            line-height: 26px;
            color: #160E25;
            margin-top:20px;
            text-align: center;
        }
        .web_url{
            font-family: 'Titillium Web';
            font-style: normal;
            font-weight: 700;
            font-size: 14px;
            line-height: 14px;
            color: #68548E;
            margin-top:15px;
            text-align: center;
        }
        width: 100%;
        display: flex;
        flex-direction: column;
        align-items: center;
        padding:0 24px;
        box-sizing: border-box;
    }
    .wait{
        width:100%;
        display: flex;
        flex-direction: column;
        align-items: center;
        justify-content: center;
        height:40vh;
        .wait_text{
            font-family: 'Titillium Web';
            font-style: normal;
            font-weight: 700;
            font-size: 14px;
            line-height: 18px;
            color: #160E25;
            margin-top:20px;
        }
    }
}
</style>