

const globalModule = {
  state: {
    allSymbol:[],
    chainMapping:[],
    userInfo:{},
    sharelist:[]
  },
  getters: {
    chainSymbol(state) {
      return chain => {
        return state.allSymbol.filter(item => {
          return item.chainName == chain;
        });
      };
    },
    symbolOfID(state) {
      return id => {
        return state.allSymbol.find(item => {
          return item.id == id;
        });
      };
    },
    chainOf(state) {
      // chainName chainId id
      return (key,value) => {
        return state.chainMapping.find(item => {
          return item[key] == value;
        });
      };
    }
  },
  mutations: {
    allSymbol(state, allSymbol){
      state.allSymbol = allSymbol;
    },
    chainMapping(state, chainMapping){
      state.chainMapping = chainMapping;
    },
    userInfo(state, userInfo){
      state.userInfo = userInfo;
    },
    sharelist(state, sharelist){
      state.sharelist = sharelist;
    }
  },
  actions: {
    
  }
};
export default globalModule;
