<template>
	<div class="no_data" :style="{
		height:`${height}px`
	}">
		<div class="center">
			<!-- <GI class="loading" :rotate="true" type="png" name="backup_loading" ws="30" hs="30"></GI> -->
			<span>{{langJson("No Request")}}</span>
			
		</div>
	</div>
</template>

<script>
	export default {
		name:"NuData",
		data() {
			return {
				
			};
		},
		props:{
			wait_load:{
				type:Boolean,
				default:false
			},
			height:{
				type:[String,Number],
				default:300
			}
		}
	}
</script>

<style lang="scss" scoped>
.no_data{
	.center{
		display: flex;
		flex-direction: column;
		align-items: center;
		.loading{
			margin-bottom:20px;
		}
	}
	width:100%;
	display: flex;
	align-items: center;
	justify-content: center;
	
	font-family: 'Titillium Web';
	font-style: normal;
	font-weight: 700;
	font-size: 28px;
	line-height: 36px;
}
</style>