<template>
	<div class="wallet_list">
		<div :class="[
			'wallet_item',
			wallet.address == item.wallet && wallet.chainId == item.chainId ? 'active' : ''
		]" v-for="(item,index) in list" :key="index" @click="changeChain(item)">
			<div class="wallet_name">{{item.net}}</div>
			<div class="wallet_detail">
				<div class="wallet_address">{{item.wallet | hideStr}}</div>
			</div>
		</div>
	</div>
</template>

<script>
	export default {
		name:"WalletList",
		data() {
			return {
				wallets:[]
			};
		},
		created() {

		},
		props:{
            wallet:{
				type:Object,
				default:() => {
					return {}
				}
			},
			list:{
				type:Array,
				default:() => {
					return []
				}
			}
		},
		
		methods:{
			async changeChain(walletItem) {
				let { wallet,chainId } = walletItem
				this.$emit('change',chainId,wallet)
			}
		}
	}
</script>

<style lang="scss" scoped>
.wallet_list{
	
	.wallet_item{
		.wallet_name{
			font-family: 'Titillium Web';
			font-style: normal;
			font-weight: 700;
			font-size: 14px;
			line-height: 18px;
			color: #442B73;
		}
		.wallet_detail{
			.wallet_address{
				font-family: 'Titillium Web';
				font-style: normal;
				font-weight: 700;
				font-size: 12px;
				line-height: 14px;
				color: #7A728A;
				// margin-right:8px;
			}
			display: flex;
			align-items: center;
			height:100%;
		}
		
		display: flex;
		align-items: center;
		justify-content: space-between;
		height:40px;
		padding:0 12px;
		width:100%;
		margin-bottom:8px;
		box-sizing: border-box;
	}
	
	.active{
		background: #F2EFF7!important;
		border-radius: 17px!important;
	}
	display: flex;
	flex-direction: column;
	align-items: flex-start;
	padding: 24px;
	background: #FFFFFF;
	height:100%;
	overflow: auto;
	box-sizing: border-box;
}
</style>