<template>
  <div :class="['mask',show ? 'maskShow' : 'maskHide']" :style="{width}">
      <div v-if="show" :class="['inner',show ? 'innerShow' : 'innerHide']">
          <div class="inner_main">
            <div class="inner_content">
              <slot name="main"></slot>
            </div>
            <div class="btns" v-if="btn">
                <BTN @handle="cancle" :disable="!peerMeta" :load="loading">{{langJson("Cancle")}}</BTN> 
                <BTN type="active" :disable="!peerMeta" @handle="confirm" :load="loading">{{langJson("Confirm")}}</BTN>
            </div> 
          </div>
      </div>
  </div>
</template>

<script>
export default {
  name: "Confirm",
  data() {
    return {
      loading: false,
      width:"0%"
    };
  },
  model:{
    prop:"show",
    event:"change"
  },
  props:{
    show:{
        type:Boolean,
        default:false
    },
    btn:{
        type:Boolean,
        default:true
    },
    peerMeta:{
      type:Object,
      default:() => {
        return null
      }
    }
  },
  watch:{
    show:function(n) {
        if(n) {
            this.open()
        }else{
          this.close()
        }
    }
  },
  methods: {
    open(){
      this.width = "100%"
    },
    close(){
    //   this.isShow = false
      this.$emit('change',false)
      setTimeout(()=>{
        this.width = "0%"
      },500)
    },
    confirm() {
        this.loading = true
        this.close()
        this.$emit('Confirm')
        this.loading = false
    },
    cancle() {
        this.loading = true
        this.close()
        this.$emit('Cancle')
        this.loading = false
    }
  }
};
</script>

<style lang="scss" scoped>


.btns{
  display: flex;
  align-items: center;
  justify-content: space-between;
  width:100%;
  box-sizing: border-box;
  padding:24px;
  box-sizing: border-box;
  .btn{
    height:50px;
    display: flex;
    align-items: center;
    justify-content: center;
    // background-color: #151625;
    color:#151625;
    width:45%;
    border-radius: 6px;
    font-family: "Titillium Web";
    font-weight: 700;
  }
}
.mask {
  position: fixed;
  top:0;
  bottom:0;
  background: rgba(0, 0, 0, 0.4);
  display: flex;
  align-items: flex-end;
  justify-content: center;
  z-index: 10000;
  .inner {
    .inner_main{
        min-height: 50vh;
        max-height: 100vh;
        overflow: auto;
        display: flex;
        flex-direction: column;
        justify-content: space-between;
        .inner_content{
          width:100%;
          height: auto;
        }
    }
    .title{
      color:#fff;
      font-size:18px;
      height:50px;
      line-height: 50px;
    }
    .tip{
      color:#fff;
      font-size:14px;
    }
    position: relative;
    width: 500px;
    border-radius: 16px 16px 0 0;
    background-color: #fff;
    display: flex;
    flex-direction: column;
    overflow: hidden;
  }
}
@media screen and (max-width: 500px) {
  .mask{
    .inner{
      width:100%;
    }
  }
}
.maskShow {
  animation: maskShow 0.5s ease-in forwards;
}
.maskHide {
  animation: maskHide 0.5s ease-in forwards !important;
}
@keyframes maskShow {
  0% {
    opacity: 0;
  }
}
@keyframes maskHide {
  100% {
    opacity: 0;
  }
}

.innerShow {
  animation: innerShow 0.5s ease;
}
.innerHide {

  animation: innerHide 0.5s ease forwards;
}
@keyframes innerShow {
  0% {
    transform: translateY(100vh);
    opacity: 0;
  }
  100% {
    transform: translateY(0px);
  }
}
@keyframes innerHide {
  0% {
    transform: translateY(0px);
    opacity: 0;
  }
  100% {
    transform: translateY(100vh);
  }
}
</style>


