
import Clipboard from "clipboard";
export default {
    langJson(str,key){

        return str
    },
    timeFormat(timeStr) {
        timeStr = timeStr.replace(/-/g, "/");
    
        let timeNum = new Date(timeStr).getTime();
        return parseInt(timeNum / 1000);
    },
    copy(tag) {
      var clipboard = new Clipboard(tag);
      clipboard.on("success", e => {
        this.$tip.success(this.langJson("复制成功",'gameFI.txt1009'));
        clipboard.destroy();
      });
      clipboard.on("error", e => {
        this.$tip.error(this.langJson("复制失败",'gameFI.txt10010'));
        clipboard.destroy();
      });
    },
    openAddress(address,chain = '') {
      chain = chain ? chain : this.walletDetail.chain
      let baseUrl = this.findChain(chain).browserAddress
      window.open(baseUrl + address, "_blank");
    },
    openUrl(url) {
      window.open(url, "_blank");
    },
    openTx(hash,chain = '') {
      chain = chain ? chain : this.walletDetail.chain
      let baseUrl = this.findChain(chain).browserTx
      window.open(baseUrl + hash, "_blank");
    },
    async playBefore(cid,fn = ()=>{},params = []){
        try{
          let result;
          let finish;
          if(!this.walletDetail.chainDetail || this.walletDetail.chainDetail.id != cid){
              result = await this.switchNetwork(this.findChainCID(cid))
              if(result){
                finish = await this.connectWallet()
                finish && fn(...params)
              }else{
                this.$tip.error('Failed to login wallet!');
              }
          }else if(this.walletDetail.chainDetail.id == cid){
            fn(...params)
          }else{
            this.$tip.error('Failed to login wallet!');
          }
        }catch(err){
          console.log(err)
        }
    }
    
}