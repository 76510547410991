<template>
	<img :style="{
		width:ws ? `${ws}px` : '100%',
		height:hs ? `${hs}px` : 'auto'
	}" :class="['icon',rotate ? 'rotate' : '']" :src="`./source/${type}/${name}.${type}`" @click.stop="$emit('click')" />
</template>

<script>
	export default {
		name:"GlobalSvg",
		data() {
			return {
				
			};
		},
		props:{
			type:{
				type:String,
				default:'svg'
			},
			name:{
				type:String,
				default:''
			},
			ws:{
				type:[Number,String],
				default:0
			},
			hs:{
				type:[Number,String],
				default:0
			},
			rotate:{
				type:Boolean,
				default:false
			}
		}
	}
</script>

<style lang="scss" scoped>
.icon{
	width: 100%;
	height: 100%;
}

@keyframes rotateHandle {
	from{transform: rotate(0deg);}
	to{transform: rotate(360deg);}
}
.rotate{
	animation: rotateHandle 1s linear forwards infinite;
}
</style>
