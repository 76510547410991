<template>
    <div class="request">
        <div class="title">{{langJson("Result: ")}}</div>
        <textarea class="params" name="" v-model="result"
        :disabled="true" id="" cols="30" rows="10"></textarea>
    </div>
</template>
<script>
export default {
    data(){
        let that = this
        return {
            
        }
    },
    props:{
        result:{
            type:String,
            default:""
        }
    }
}
</script>
<style lang="scss" scoped>
.request{
    .params{
        padding: 15px;
        background: #F7F7F7;
        border-radius: 6px;
        margin-bottom:10px;
        font-family: 'Titillium Web';
        font-style: normal;
        font-weight: 600;
        font-size: 10px;
        line-height: 12px;
        color: #442B73;
        width:100%;
        box-sizing: border-box;
    }
    .title{
       font-family: 'Titillium Web';
       font-style: normal;
       font-weight: 700;
       font-size: 18px;
       line-height: 28px;
       color: #000000;
       margin-bottom:10px; 
    }
    .method{
        font-family: 'Titillium Web';
        font-style: normal;
        font-weight: 700;
        font-size: 18px;
        line-height: 28px;
        color: #000000;
        margin-bottom:20px;
    }
    padding:24px;
    box-sizing: border-box;
}
</style>