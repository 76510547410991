// import computed from './computed'
import tool from './tool'
// import request from './request'
// import jumpPage from './jumpPage'
// import contract from './contract'
import wallet_play from './wallet_play'
export default {
    data() {
        return {
            
        }
    },
    // computed,
    filters:{
        hideStr(val) {
            if (!val) {
                return val
            }
            return val.replace(/^(.{6}).*(.{6})$/, '$1......$2')
        }
    },
    methods: {
        ...tool,
        // ...request,
        ...wallet_play,
        // ...jumpPage,
        // ...contract
    }
}