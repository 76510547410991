<template>
	<div :class="['btn',type,disable || load ? 'disable' : '']" @click="emitEvent" :style="Object.assign(styleCustom,{ height })">
		<GI v-if="icon_left" class="icon_left" 
		:name="icon_left" :ws="size ? size : ws" 
		:hs="size ? size : hs"></GI>
		
		<slot></slot>
		
		<GI v-if="icon_right" class="icon_right" 
		:name="icon_right" :ws="size ? size : ws" 
		:hs="size ? size : hs"></GI>
		<div class="mask" v-if="load">
			<GI class="loading" :rotate="true" type="png" name="backup_loading" ws="30" hs="30"></GI>
		</div>
	</div>
</template>

<script>
	export default {
		name:"BTN",
		data() {
			return {
				lockStatus:false,
				lockTime:1000
			};
		},
		props:{
			icon_left:{
				type:String,
				default:""
			},
			icon_right:{
				type:String,
				default:""
			},
			type:{
				type:String,
				default:""
			},
			disable:{
				type:Boolean,
				default:false
			},
			load:{
				type:Boolean,
				default:false
			},
			size:{
				type:[String,Number],
				default:""
			},
			ws:{
				type:[String,Number],
				default:"20"
			},
			hs:{
				type:[String,Number],
				default:"20"
			},
			height:{
				type:String,
				default:"56px"
			},
			styleCustom: {
				type:Object,
				default:() => {
					return {}
				}
			}
		},

		methods:{
			emitEvent(){
				if(!this.disable && !this.load && !this.lockStatus) {
					this.lockStatus = true
					setTimeout(()=>{
						this.lockStatus = false
					},this.lockTime)
					this.$emit('handle')
				}
				
			}
		}
	}
</script>

<style lang="scss" scoped>
.btn{
	.icon_left{
		margin-right:10px;
		
	}
	.icon_right{
		margin-left:10px;
		
	}
	display: flex;
	flex-direction: row;
	justify-content: center;
	align-items: center;
	// padding: 1px 10px 1px 1px;
	padding:0 18px;
	box-sizing: border-box;
	gap: 10px;
	background: #FFFFFF;
	box-shadow: 0px 1.29724px 6.48619px rgba(134, 134, 134, 0.2);
	border-radius: 10px;
	width:100%;
	font-family: 'Titillium Web';
	font-style: normal;
	font-weight: 700;
	font-size: 16px;
	line-height: 20px;
	position: relative;
	overflow: hidden;
	text-overflow: ellipsis;
	white-space: nowrap;
	.mask{
		position: absolute;
		top:0;
		left:0;
		right:0;
		bottom:0;
		display: flex;
		align-items: center;
		justify-content: center;
	}
}
.active{
	background: #246EF6!important;
	box-shadow: 0px 20px 25px -5px rgba(36, 110, 246, 0.1), 0px 8px 10px -6px rgba(36, 110, 246, 0.1)!important;
	border-radius: 10px!important;
	color:#fff!important;
}
.yes{
	background: linear-gradient(90deg, #C589F9 27.34%, #EC52D4 100%)!important;
	box-shadow: 0px 20px 25px -5px rgba(213, 115, 234, 0.1), 0px 8px 10px -6px rgba(186, 0, 251, 0.1)!important;
	border-radius: 10px!important;
	color:#fff!important;
}
.skip{
	background: #DCD7E5!important;
	box-shadow: 0px 0px 0px rgba(134, 134, 134, 0)!important;
	border-radius: 10px!important;
	color:#fff!important;
}
.disable{
	opacity: 0.5;
}
.loading{
	
}
</style>