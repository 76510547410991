<template>
    <div class="request">
        <div class="method">{{langJson("Event: ")}}{{method}}</div>
        <!-- <div class="request_item">
            <div class="lable"></div>
            <div class="val"></div>
        </div> -->
        <!-- <div class="params" v-for="(item,index) in payload.params" :key="index">
            {{item}}
        </div> -->
        <div class="title">{{langJson("Params: ")}}</div>
        <textarea v-for="(item,index) in payload.params" :key="index" class="params" name="" 
        v-model="params[index]"
        :disabled="true" id="" cols="30" rows="10"></textarea>
    </div>
</template>
<script>
export default {
    data(){
        let that = this
        return {
            messageMethod:{
                eth_signTransaction:that.langJson("Initiate transaction (eth_signTransaction)"),
                eth_sendTransaction:that.langJson("Transaction signature (eth_sendTransaction)"),
                eth_sign:that.langJson("Message signature (eth_sign)"),
                personal_sign:that.langJson("Message signature (personal_sign)"),
                eth_signTypedData:that.langJson("Message signature (eth_signTypedData)")

            },
        }
    },
    computed:{
        method(){
            return this.messageMethod[this.payload.method]
        },
        params(){
            return this.payload.params.map(item=>{
                if(typeof item == 'object'){
                    return JSON.stringify(item)
                }else{
                    return item
                }
            })
        }
    },
    props:{
        payload:{
            type:Object,
            default:() => {
                return null
            }
        }
    }
}
</script>
<style lang="scss" scoped>
.request{
    .params{
        padding: 15px;
        background: #F7F7F7;
        border-radius: 6px;
        margin-bottom:10px;
        font-family: 'Titillium Web';
        font-style: normal;
        font-weight: 600;
        font-size: 10px;
        line-height: 12px;
        color: #442B73;
        width:100%;
        box-sizing: border-box;
    }
    .title{
       font-family: 'Titillium Web';
       font-style: normal;
       font-weight: 700;
       font-size: 18px;
       line-height: 28px;
       color: #000000;
       margin-bottom:10px; 
    }
    .method{
        font-family: 'Titillium Web';
        font-style: normal;
        font-weight: 700;
        font-size: 18px;
        line-height: 28px;
        color: #000000;
        margin-bottom:20px;
    }
    padding:24px;
    box-sizing: border-box;
}
</style>